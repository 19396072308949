<template>
  <div class="header__bell">
    <div @click="toggle" class="header__bell--image">
      <img
        v-if="!hasUnreadNotifications"
        src="@/assets/images/icon/bell.svg"
        alt=""
      />
      <img
        v-if="hasUnreadNotifications"
        src="@/assets/images/icon/bell-active.svg"
        alt=""
      />
    </div>

    <OverlayPanel ref="op">
      <div class="header__bell--content">
        <div class="header__bell--head">
          <span class="caption">{{ $t("menu.notifications") }}</span>
          <!--          <router-link @click="toggle" :to="{ name: 'notifications' }">-->
          <!--            {{ $t("common.all") }}-->
          <!--          </router-link>-->
        </div>
        <div v-if="hasNotifications" class="header__bell--card">
          <router-link
            @click="readAndToggle(event, item.id)"
            :to="{
              name: 'notifications',
            }"
            v-for="item in this.notifications.list"
            :key="item.id"
            href=""
            class="header__bell--item"
            :class="{ active: !item.isRead }"
          >
            <div class="bell-images icon">
              <img src="@/assets/images/icon/page/Academy.svg" alt="" />
            </div>

            <div class="bell-content">
              <div class="bell-description">
                <strong v-html="item.notification.theme"></strong>
              </div>
              <span class="bell-date">
                {{ this.$moment(item.notification.dateCreated).fromNow() }}
              </span>
            </div>
          </router-link>
        </div>

        <div v-else class="empty">Нет уведомлений</div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OverlayPanel from "primevue/overlaypanel";
import crudService from "@/services/crudService";

export default {
  name: "RockHeaderNotification",
  components: { OverlayPanel },
  computed: {
    ...mapGetters(["notifications"]),
    hasNotifications() {
      return this.notifications && this.notifications.list.length > 0;
    },
    hasUnreadNotifications() {
      if (!this.hasNotifications) {
        return false;
      }

      return this.notifications.list.find((item) => {
        return item.isRead === false;
      });
    },
  },
  methods: {
    ...mapActions(["getNotifications"]),
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    readAndToggle(event, id) {
      crudService.getOne("notifications", id).then(
        () => {
          this.getNotifications();
          this.$refs.op.toggle(event);
        },
        (fail) => {
          this.$toast.add({
            severity: "error",
            detail: fail.message || fail.detail,
            life: 9000,
          });
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.header__bell--content {
  display: block;
  top: 15px;
  right: -140px;
  height: auto;

  @media screen and (max-width: 568px) {
    right: -90px;
  }
}
</style>
