<template>
  <section class="header-news">
    <div class="header-news__wrapper">
      <div class="header-news__items">
        <div class="header-news__content">
          <router-link
            v-for="item in news"
            :key="item.id"
            :to="{ name: 'news-item', params: { slug: item.slug } }"
            class="header-news__item"
          >
            <span class="header-news__date">
              {{ this.$moment(item.datePublished).format("LL") }}
            </span>
            <span class="header-news__title">{{ item.title }}</span>
          </router-link>
        </div>
      </div>
      <div class="header-news__all">
        <router-link :to="{ name: 'news' }">
          {{ $t("common.all_news") }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderNews",
  computed: {
    ...mapGetters({
      news: "news/top",
    }),
  },
};
</script>

<style scoped>
.header-news__all a {
  color: #fff;
}
</style>
