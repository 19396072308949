<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__container">
        <div class="footer__logo">
          <a href="">
            <img src="@/assets/images/logo.svg" alt="" />
          </a>
        </div>
        <nav class="footer__nav">
          <ul class="footer__nav-list">
            <a
              target="_blank"
              href="https://relaxstation-club.com/landing/relax-network"
              class="footer__nav-item"
            >
              Club House
            </a>
            <a
              target="_blank"
              href="https://magicrecreation.com"
              class="footer__nav-item"
            >
              Relax Room
            </a>
            <a
              target="_blank"
              href="https://relaxstation-club.com/landing/relax-mlm"
              class="footer__nav-item"
            >
              MLM Project
            </a>
          </ul>

          <ul class="footer__nav-list">
            <a
              target="_blank"
              href="https://relaxstation-club.com/page/business"
              class="footer__nav-item"
            >
              {{ $t("footer.travel") }}
            </a>
            <a
              href="https://relaxstation-club.com/page/health"
              class="footer__nav-item"
            >
              {{ $t("footer.health") }}
            </a>
            <a
              href="https://relaxstation-club.com/page/fund"
              class="footer__nav-item"
            >
              {{ $t("footer.fund") }}
            </a>
          </ul>

          <ul class="footer__nav-list">
            <a
              target="_blank"
              href="https://relaxstation-club.com/page/about"
              class="footer__nav-item"
            >
              {{ $t("footer.about_us") }}
            </a>

            <router-link class="footer__nav-item" :to="{ name: 'news' }">
              {{ $t("footer.news") }}
            </router-link>
            <router-link class="footer__nav-item" :to="{ name: 'support' }">
              {{ $t("footer.faq") }}
            </router-link>
            <a
              target="_blank"
              href="https://relaxstation-club.com/reviews"
              class="footer__nav-item"
            >
              {{ $t("footer.reviews") }}
            </a>
            <a
              href="https://relaxstation-club.com/page/contacts"
              class="footer__nav-item"
            >
              {{ $t("footer.contacts") }}
            </a>
          </ul>
        </nav>
        <div class="footer__social">
          <div class="footer__icon">
            <a target="_blank" href="https://www.facebook.com/relaxstationclub">
              <img src="@/assets/images/icon/facebook.svg" alt="" />
            </a>
            <a target="_blank" href="https://t.me/relaxstationclub">
              <img src="@/assets/images/icon/telegram.svg" alt="" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/relaxstationclub"
            >
              <img src="@/assets/images/icon/instagram.svg" alt="" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCnb6ZRb2RXpE4vAO1cl9NSQ/featured"
            >
              <img src="@/assets/images/icon/youtube.svg" alt="" />
            </a>
          </div>
          <div class="footer__certificate">
            <a href="">
              <img src="@/assets/images/visa.jpg" alt="" />
            </a>
            <a href="">
              <img src="@/assets/images/adv.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-txt">
        <span class="copirayt">© 2024 Relax Station International Club</span>
        <a
          href="https://relaxstation-club.com/pages/privacy-rules"
          class="politica"
        >
          {{ $t("footer.privacy_and_policy") }}
        </a>
        <a target="_blank" href="https://semseoweb.com/" class="developer">
          {{ $t("footer.developed_by") }} SEMSEO
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "RockFooter",
};
</script>
