<template>
  <a href="#" @click.prevent="switchLang()" class="header__lang">
    <div class="en" :class="{ active: $i18n.locale === 'en' }">
      <span class="icon">
        <img src="@/assets/images/icon/lang/en.svg" alt="" />
      </span>
      <span class="title">EN</span>
    </div>
    <div :class="{ active: $i18n.locale === 'ru' }" class="en">
      <span class="icon">
        <img src="@/assets/images/icon/lang/ru.svg" alt="" />
      </span>
      <span class="title">RU</span>
    </div>
  </a>
  <div class="gtranslate_wrapper"></div>
</template>

<script>
import crudService from "@/services/crudService";

export default {
  name: "RockHeaderLang",
  methods: {
    switchLang() {
      let locale = this.$i18n.locale === "ru" ? "en" : "ru";

      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);

      crudService.updateSelf("users", { locale: locale });

      // let html = document.getElementsByName("html");
      // html.setAttribute("lang", locale);

      location.reload();
    },
  },
  created() {
    let scr1 = document.createElement("script");
    scr1.appendChild(
      document.createTextNode(
        'window.gtranslateSettings = {"default_language":"ru","native_language_names":true,"detect_browser_language":true,"languages":["ru", "en", "de", "fr", "tr", "it", "es", "uk", "ro", "mn", "pl", "pt", "kk", "bg", "az", "ka", "ky"],"wrapper_selector":".gtranslate_wrapper"}'
      )
    );
    document.head.appendChild(scr1);

    let scr = document.createElement("script");
    scr.setAttribute(
      "src",
      "https://cdn.gtranslate.net/widgets/latest/dropdown.js"
    );
    document.head.appendChild(scr);
  },
};
</script>

<style lang="scss" scoped>
.gtranslate_wrapper {
  a {
    display: none;
  }
}
</style>
