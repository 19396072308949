import axios from "axios";

class UserService {
  login(user) {
    return axios
      .post("login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  register(request) {
    return axios.post("register", request).then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
  }

  me(request) {
    return axios.get("me", request);
  }

  balance(request) {
    return axios.get("balance", request);
  }

  registerValidate(request) {
    return axios.post("register/validate", request).then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
  }

  async switchUser(username) {
    return await axios
      .get(`user/switch`, {
        headers: {
          "X-Switch-User": username,
        },
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem(
            "impersonatedUser",
            localStorage.getItem("user")
          );
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  async search(query) {
    return axios.post("clients/search", query);
  }

  async uploadAvatar(id, data) {
    return await axios.post(`clients/${id}/avatar`, data);
  }

  logout() {
    if (localStorage.getItem("impersonatedUser")) {
      localStorage.setItem("user", localStorage.getItem("impersonatedUser"));
      localStorage.removeItem("impersonatedUser");
    } else {
      localStorage.removeItem("user");
    }
  }
}

export default new UserService();
