import axios from "axios";

class crudService {
  async get(path, params) {
    return await axios.get(path, { params });
  }

  async getList(path, params) {
    return await axios.get(path, { params });
  }

  async getOne(path, id) {
    return await axios.get(`${path}/${id}`);
  }

  async getOneParams(path, id, params) {
    return await axios.get(`${path}/${id}`, { params });
  }

  async getOneAction(path, id, action) {
    return await axios.get(`${path}/${id}/${action}`);
  }

  async getOneActionParams(path, id, action, params) {
    return await axios.get(`${path}/${id}/${action}`, { params });
  }

  async create(path, data) {
    return await axios.post(path, data);
  }

  async postParam(path, id, data) {
    return await axios.post(`${path}/${id}`, data);
  }

  async patch(path, id) {
    return await axios.patch(`${path}/${id}`);
  }

  async update(path, id, data) {
    return await axios.put(`${path}/${id}`, data);
  }

  async upload(path, data) {
    return await axios.post(path, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async updateAction(path, id, action, data) {
    return await axios.put(`${path}/${id}/${action}`, data);
  }

  async updateSelf(path, data) {
    return await axios.put(`${path}`, data);
  }

  async delete(path, id) {
    return await axios.delete(`${path}/${id}`);
  }

  async getStatusList(path) {
    return await axios.get(`${path}/statuses`);
  }

  async getFilterList(path) {
    return await axios.get(`${path}/all`);
  }
}

export default new crudService();
