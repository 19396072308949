<template>
  <div class="header__wallet">
    <div @click="toggle" class="header__wallet--info">
      <div class="header__wallet--icon">
        <img src="@/assets/images/icon/wallet.svg" alt="" />
      </div>
      <div class="header__wallet--txt">
        <span class="title">{{ $t("finance.my_balance") }}</span>
        <span class="balance">
          {{ this.balance }}
          <span class="rlx">RLX</span>
        </span>
      </div>
    </div>

    <OverlayPanel ref="op">
      <div class="header__wallet--content">
        <div class="mobile-info-w">
          <span class="title">{{ $t("finance.my_balance") }}</span>
          <span class="balance">
            {{ this.balance }}
            <span class="rlx">RLX</span>
          </span>
        </div>
        <router-link
          :to="{ name: 'replenish' }"
          @click="toggle()"
          class="header__wallet--item"
        >
          <span class="icon">
            <img src="@/assets/images/icon/replenish.svg" alt="" />
          </span>
          <span class="caption">{{ $t("finance.replenish") }}</span>
        </router-link>
        <router-link
          :to="{ name: 'withdraw' }"
          @click="toggle()"
          class="header__wallet--item"
        >
          <span class="icon">
            <img src="@/assets/images/icon/output.svg" alt="" />
          </span>
          <span class="caption">{{ $t("finance.withdraw") }}</span>
        </router-link>
        <router-link
          :to="{ name: 'transfer' }"
          @click="toggle()"
          class="header__wallet--item"
        >
          <span class="icon">
            <img src="@/assets/images/icon/exchange-head.svg" alt="" />
          </span>
          <span class="caption">{{ $t("finance.transfer") }}</span>
        </router-link>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";

export default {
  name: "RockHeaderWallet",
  components: { OverlayPanel },
  props: ["balance"],
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
  },
};
</script>

<style scoped lang="scss">
.header__wallet--content {
  display: block;
  top: 15px;
  right: -140px;

  @media screen and (max-width: 568px) {
    right: -90px;
  }
}
</style>
