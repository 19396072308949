import crudService from "../services/crudService";

const path = "news";

export const news = {
  namespaced: true,
  state: {
    list: [],
    top: [],
    related: [],
    categories: [],
    item: null,
  },
  actions: {
    async getList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_LIST", response.data);
      });
    },
    async getTop({ commit }) {
      await crudService.getList("news/top", {}).then((response) => {
        commit("SET_TOP", response.data);
      });
    },
    async getRelated({ commit }) {
      await crudService.getList("news/related", {}).then((response) => {
        commit("SET_RELATED", response.data);
      });
    },
    async getCategories({ commit }, params) {
      await crudService.getList("news/categories", params).then((response) => {
        commit("SET_CATEGORIES", response.data);
      });
    },
    async getItem({ commit }, id) {
      await crudService.getOne(path, id).then((response) => {
        commit("SET_ITEM", response.data);
      });
    },
  },
  mutations: {
    SET_LIST: (state, list) => {
      state.list = list;
    },
    SET_TOP: (state, top) => {
      state.top = top;
    },
    SET_RELATED: (state, related) => {
      state.related = related;
    },
    SET_CATEGORIES: (state, categories) => {
      state.categories = categories;
    },
    SET_ITEM: (state, item) => {
      state.item = item;
    },
  },
  getters: {
    list: (state) => state.list,
    top: (state) => state.top,
    related: (state) => state.related,
    categories: (state) => state.categories,
    item: (state) => state.item,
  },
};
