import crudService from "../services/crudService";

export const academy = {
  namespaced: true,
  state: {
    matrix: [],
    matrixYear: new Date().getFullYear(),
    relay: [],
    relayStats: [],
    relayMonthStats: [],
    relayTotal: [],
    team: [],
    qualifications: [],
    topics: [],
    purchases: [],
    countries: [],
    helps: [],
    incomes: [],
    user_packages: [],
    user_packages_distinct: [],
  },
  actions: {
    async getMatrix({ commit }, params = null) {
      await crudService.getList("referrals/matrix", params).then((response) => {
        const year = params ? params.year : new Date().getFullYear();

        commit("SET_MATRIX_YEAR", year);
        commit("SET_MATRIX", response.data);
      });
    },
    async getTeam({ commit }, params = null) {
      await crudService
        .getList("referrals/matrix/full", params)
        .then((response) => {
          commit("SET_TEAM", response.data);
        });
    },
    async getRelay({ commit }, params = null) {
      await crudService.getList("relay", params).then((response) => {
        const year = params ? params.year : new Date().getFullYear();

        commit("SET_RELAY", response.data.matrix);
        commit("SET_RELAY_STATS", response.data.stats);
        commit("SET_RELAY_MONTH_STATS", response.data.monthStats);
        commit("SET_RELAY_TOTAL", response.data.total);
        commit("SET_RELAY_QUALIFICATIONS", response.data.qualifications);
        commit("SET_MATRIX_YEAR", year);
      });
    },
    async getTopics({ commit }, path, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_TOPICS", response.data);
      });
    },

    async getPurchases({ commit }) {
      await crudService.getList("academy/topics/purchases").then((response) => {
        commit("SET_PURCHASES", response.data);
      });
    },
    async getCountries({ commit }) {
      await crudService.getList("countries").then((response) => {
        commit("SET_COUNTRIES", response.data);
      });
    },
    async getHelps({ commit }) {
      await crudService.getList("helps").then((response) => {
        commit("SET_HELPS", response.data);
      });
    },
    async getIncomes({ commit }, params = null) {
      await crudService.getList("referrals/income", params).then((response) => {
        const year = params ? params.year : new Date().getFullYear();
        commit("SET_MATRIX_YEAR", year);

        commit("SET_INCOMES", response.data);
      });
    },
    async getUserPackages({ commit }, path, params) {
      await crudService
        .getList("packages/purchases", params)
        .then((response) => {
          commit("SET_USER_PACKAGES", response.data);
        });
    },
    async getUserPackagesDistinct({ commit }, path, params) {
      await crudService
        .getList("packages/purchases/distinct", params)
        .then((response) => {
          commit("SET_USER_PACKAGES_DISTINCT", response.data);
        });
    },
  },
  mutations: {
    SET_MATRIX: (state, item) => {
      state.matrix = item;
    },
    SET_RELAY: (state, item) => {
      state.relay = item;
    },
    SET_RELAY_STATS: (state, item) => {
      state.relayStats = item;
    },
    SET_RELAY_MONTH_STATS: (state, item) => {
      state.relayMonthStats = item;
    },
    SET_RELAY_TOTAL: (state, item) => {
      state.relayTotal = item;
    },
    SET_RELAY_QUALIFICATIONS: (state, item) => {
      state.qualifications = item;
    },
    SET_MATRIX_YEAR: (state, item) => {
      state.matrixYear = item;
    },
    SET_TOPICS: (state, item) => {
      state.topics = item;
    },
    SET_PURCHASES: (state, item) => {
      state.purchases = item;
    },
    SET_COUNTRIES: (state, item) => {
      state.countries = item;
    },
    SET_HELPS: (state, item) => {
      state.helps = item;
    },
    SET_INCOMES: (state, item) => {
      state.incomes = item;
    },
    SET_TEAM: (state, item) => {
      state.team = item;
    },
    SET_USER_PACKAGES: (state, item) => {
      state.user_packages = item;
    },
    SET_USER_PACKAGES_DISTINCT: (state, item) => {
      state.user_packages_distinct = item;
    },
  },
  getters: {
    matrix: (state) => state.matrix,
    relay: (state) => state.relay,
    relayStats: (state) => state.relayStats,
    relayMonthStats: (state) => state.relayMonthStats,
    relayTotal: (state) => state.relayTotal,
    qualifications: (state) => state.qualifications,
    matrixYear: (state) => state.matrixYear,
    topics: (state) => state.topics,
    team: (state) => state.team,
    purchases: (state) => state.purchases,
    countries: (state) => state.countries,
    helps: (state) => state.helps,
    incomes: (state) => state.incomes,
    wallets: (state) => state.wallets,
    user_packages: (state) => state.user_packages,
    user_packages_distinct: (state) => state.user_packages_distinct,
  },
};
