<template>
  <nav class="header__list">
    <template v-for="item in items" :key="item.link">
      <router-link
        v-if="item.type === 'local'"
        class="header__list-item"
        :to="{ name: item.link }"
        :class="{ active: isActiveRoute(item.link) }"
      >
        {{ item.name }}
      </router-link>
      <a
        v-else
        :target="item.target ?? '_blank'"
        class="header__list-item"
        :href="item.link"
      >
        {{ item.name }}
      </a>
    </template>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RockHeaderMenu",
  data: () => ({
    items: [
      { link: "relay", name: "Together To Success", type: "local" },
      {
        link: "https://social.relaxstation-club.com",
        name: "Club House",
        type: "external",
      },
      {
        link: "https://magicrecreation.com",
        name: "Relax Room",
        type: "external",
      },
      { link: "mlm", name: "MLM & BUSINESS", type: "local" },
    ],
  }),
  computed: {
    ...mapGetters(["socialUrl"]),
  },
  methods: {
    isActiveRoute(link) {
      return this.$route.name === link;
    },
  },
  watch: {
    socialUrl: function (newVal) {
      if (newVal) {
        if (this.socialUrl) {
          this.items[1].link = this.socialUrl.socialUrl;
        }
      }
    },
  },
};
</script>
