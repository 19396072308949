import crudService from "../services/crudService";

const path = "companies";

export const company = {
  namespaced: true,
  state: {
    list: [],
    vipList: [],
    promoList: [],
  },
  actions: {
    async getList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_LIST", response.data);
      });
    },
    async getVipList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_VIP_LIST", response.data);
      });
    },
    async getPromoList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_PROMO_LIST", response.data);
      });
    },
  },
  mutations: {
    SET_LIST: (state, list) => {
      state.list = list;
    },
    SET_VIP_LIST: (state, list) => {
      state.vipList = list;
    },
    SET_PROMO_LIST: (state, list) => {
      state.promoList = list;
    },
  },
  getters: {
    list: (state) => state.list,
    vipList: (state) => state.vipList,
    promoList: (state) => state.promoList,
  },
};
