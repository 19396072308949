import { createApp } from "vue";
import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler";
import router from "@/router";
import store from "@/store";
import moment from "moment";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import DialogService from "primevue/dialogservice";
import "@/services/axios";

import App from "@/App";

const app = createApp(App);
let locale = "ru";

const params = new URLSearchParams(window.location.search);
if (params.get("lang") && ["ru", "en"].includes(params.get("lang"))) {
  locale = params.get("lang");
  localStorage.setItem("lang", locale);
} else if (localStorage.getItem("lang")) {
  locale = localStorage.getItem("lang");
} else if (navigator.language) {
  locale = navigator.language.split("-")[0];
}

moment.locale(locale);

app.config.globalProperties.$moment = moment;

Date.prototype.toISOString = function () {
  return moment(this).format("YYYY-MM-DD HH:mm:ss");
};

const i18n = createI18n({
  locale: locale,
  pluralizationRules: {
    ru: function (choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
  messages: {
    ru: require("@/message/ru.json"),
    en: require("@/message/en.json"),
  },
});

import "@/assets/scss/_style.scss";

app
  .use(router)
  .use(store)
  .use(PrimeVue)
  .use(DialogService)
  .use(ToastService)
  .use(i18n)
  .use(moment)
  .mount("#app");
