<template>
  <div class="header__profile">
    <div @click="toggle" class="user">
      <div class="user__images">
        <img :src="avatar" alt="" />
      </div>
      <div class="user__info">
        <span class="username">{{ user.username }}</span>
        <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
      </div>
    </div>

    <OverlayPanel ref="op">
      <div class="header__profile">
        <div class="menu">
          <ul class="menu__list">
            <li class="menu__list--item">
              <router-link
                @click="toggle()"
                :to="{ name: 'profile' }"
                class="menu__link"
              >
                <span class="menu__link--icon">
                  <img src="@/assets/images/icon/profile.svg" alt="" />
                </span>
                <span class="menu__link--title">{{
                  $t("profile.my_profile")
                }}</span>
              </router-link>
            </li>

            <li class="menu__list--item">
              <router-link
                @click="toggle()"
                :to="{ name: 'finance' }"
                class="menu__link"
              >
                <span class="menu__link--icon">
                  <img src="@/assets/images/icon/money.svg" alt="" />
                </span>
                <span class="menu__link--title">
                  {{ $t("profile.my_finance") }}
                </span>
              </router-link>
            </li>

            <li class="menu__list--item">
              <router-link
                @click="toggle()"
                :to="{ name: 'my-mlms' }"
                class="menu__link"
              >
                <span class="menu__link--icon">
                  <img src="@/assets/images/icon/company.svg" alt=" " />
                </span>
                <span class="menu__link--title">Мои MLM проекты </span>
              </router-link>
            </li>

            <li class="menu__list--item">
              <router-link
                @click="toggle()"
                :to="{ name: 'support' }"
                class="menu__link"
              >
                <span class="menu__link--icon">
                  <img src="@/assets/images/icon/support.svg " alt=" " />
                </span>
                <span class="menu__link--title">
                  {{ $t("common.support") }}
                </span>
              </router-link>
            </li>

            <li class="menu__list--item">
              <a href="" @click.prevent="logOut" class="menu__link">
                <span class="menu__link--icon">
                  <img src="@/assets/images/icon/exit.svg" alt=" " />
                </span>
                <span class="menu__link--title">
                  {{ $t("profile.exit") }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
import { mapGetters } from "vuex";

export default {
  name: "RockHeaderUserMenu",
  components: { OverlayPanel },
  props: ["user"],
  computed: {
    ...mapGetters({
      avatar: "auth/avatar",
    }),
  },
  methods: {
    toggle() {
      this.$refs.op.toggle(event);
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
.header__profile .menu {
  display: block !important;
  top: 15px;
  right: -200px;

  @media screen and (max-width: 568px) {
    left: -400px;
  }
}
</style>
