import crudService from "../services/crudService";

const path = "mlms";

export const mlm = {
  namespaced: true,
  state: {
    list: [],
    vipList: [],
    promoList: [],
    consultantList: [],
  },
  actions: {
    async getList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_LIST", response.data);
      });
    },
    async getVipList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_VIP_LIST", response.data);
      });
    },
    async getPromoList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_PROMO_LIST", response.data);
      });
    },
    async getConsultantsList({ commit }, mlmSlug, params) {
      await crudService
        .getList(path + "/" + mlmSlug + "/consultants", params)
        .then((response) => {
          commit("SET_CONSULTANT_LIST", response.data);
        });
    },
  },
  mutations: {
    SET_LIST: (state, list) => {
      state.list = list;
    },
    SET_VIP_LIST: (state, list) => {
      state.vipList = list;
    },
    SET_PROMO_LIST: (state, list) => {
      state.promoList = list;
    },
    SET_CONSULTANT_LIST: (state, list) => {
      state.consultantList = list;
    },
  },
  getters: {
    list: (state) => state.list,
    vipList: (state) => state.vipList,
    promoList: (state) => state.promoList,
    consultantList: (state) => state.consultantList,
  },
};
