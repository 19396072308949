<template>
  <div class="auth-page">
    <router-view />
  </div>
</template>

<script>
import "@/assets/scss/_auth.scss";
export default {
  name: "AuthLayout",
  props: ["title"],
};
</script>
