import { createStore } from "vuex";
import { auth } from "@/store/auth.module";
import { news } from "@/store/news.module";
import { academy } from "@/store/academy.module";
import { mlm } from "@/store/mlm.module";
import { company } from "@/store/company.module";
import crudService from "@/services/crudService";

export default createStore({
  modules: {
    auth,
    news,
    academy,
    mlm,
    company,
  },
  state: {
    payments: null,
    notifications: null,
    wallets: null,
    walletsSystem: null,
    bankAccounts: null,
    rates: null,
    replenishes: null,
    withdraws: null,
    transfers: null,
    faqs: null,
    partner: null,
    partnerHelps: null,
    partnerHelpsRequiredTopics: null,
    socialUrl: null,
  },
  actions: {
    async getPayments({ commit }, params = null) {
      await crudService.getList("payments", params).then((response) => {
        commit("SET_PAYMENTS", response.data);
      });
    },
    async getPartner({ commit }, params = null) {
      await crudService.getList("partner", params).then((response) => {
        commit("SET_PARTNER", response.data);
      });
    },
    async getPartnerHelps({ commit }, params = null) {
      await crudService.getList("partnerHelps", params).then((response) => {
        commit("SET_PARTNER_HELPS", response.data);
      });
    },
    async getPartnerHelpsRequiredTopics({ commit }) {
      await crudService
        .getList("partnerHelps/requiredTopics")
        .then((response) => {
          commit("SET_PARTNER_HELPS_REQUIRED_TOPICS", response.data);
        });
    },
    async getReplenishes({ commit }) {
      await crudService.getList("replenishes").then((response) => {
        commit("SET_REPLENISHES", response.data);
      });
    },
    async getWithdraws({ commit }) {
      await crudService.getList("withdraws").then((response) => {
        commit("SET_WITHDRAWS", response.data);
      });
    },
    async getTransfers({ commit }) {
      await crudService.getList("transfers").then((response) => {
        commit("SET_TRANSFERS", response.data);
      });
    },
    async getNotifications({ commit }) {
      await crudService.getList("notifications").then((response) => {
        commit("SET_NOTIFICATIONS", response.data);
      });
    },
    async getWallets({ commit }) {
      await crudService.getList("wallets").then((response) => {
        commit("SET_WALLETS", response.data);
      });
    },
    async getWalletsSystem({ commit }) {
      await crudService.getList("wallets/system").then((response) => {
        commit("SET_WALLETS_SYSTEM", response.data);
      });
    },
    async getBankAccounts({ commit }) {
      await crudService.getList("bankAccounts").then((response) => {
        commit("SET_BANK_ACCOUNTS", response.data);
      });
    },
    async getRates({ commit }) {
      await crudService.getList("rates").then((response) => {
        commit("SET_RATES", response.data);
      });
    },
    async getFaqs({ commit }) {
      await crudService.getList("faqs").then((response) => {
        commit("SET_FAQS", response.data);
      });
    },
    async getSocialUrl({ commit }) {
      crudService.get("crossAuthUrl").then((response) => {
        commit("SET_SOCIAL_URL", response.data);

        return Promise.resolve(response);
      });
    },
  },
  mutations: {
    SET_PAYMENTS: (state, item) => {
      if (item.pagination.current > 1) {
        state.payments.pagination = item.pagination;

        for (const value of item.list) {
          state.payments.list.push(value);
        }
      } else {
        state.payments = item;
      }
    },
    SET_NOTIFICATIONS: (state, item) => {
      state.notifications = item;
    },
    SET_WALLETS: (state, item) => {
      state.wallets = item;
    },
    SET_WALLETS_SYSTEM: (state, item) => {
      state.walletsSystem = item;
    },
    SET_BANK_ACCOUNTS: (state, item) => {
      state.bankAccounts = item;
    },
    SET_RATES: (state, item) => {
      state.rates = item;
    },
    SET_REPLENISHES: (state, item) => {
      state.replenishes = item;
    },
    SET_WITHDRAWS: (state, item) => {
      state.withdraws = item;
    },
    SET_TRANSFERS: (state, item) => {
      state.transfers = item;
    },
    SET_FAQS: (state, item) => {
      state.faqs = item;
    },
    SET_PARTNER: (state, item) => {
      state.partner = item;
    },
    SET_PARTNER_HELPS: (state, item) => {
      state.partnerHelps = item;
    },
    SET_PARTNER_HELPS_REQUIRED_TOPICS: (state, item) => {
      state.partnerHelpsRequiredTopics = item;
    },
    SET_SOCIAL_URL: (state, item) => {
      state.socialUrl = item;
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.auth.status.loggedIn;
    },
    balance: (state) => state.auth.balance,
    payments: (state) => state.payments,
    notifications: (state) => state.notifications,
    wallets: (state) => state.wallets,
    walletsSystem: (state) => state.walletsSystem,
    bankAccounts: (state) => state.bankAccounts,
    rates: (state) => state.rates,
    replenishes: (state) => state.replenishes,
    withdraws: (state) => state.withdraws,
    transfers: (state) => state.transfers,
    faqs: (state) => state.faqs,
    partner: (state) => state.partner,
    partnerHelps: (state) => state.partnerHelps,
    partnerHelpsRequiredTopics: (state) => state.partnerHelpsRequiredTopics,
    socialUrl: (state) => state.socialUrl,
  },
});
