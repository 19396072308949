<template>
  <RockHeader />

  <main>
    <HeaderNews />
    <router-view />
  </main>

  <RockFooter />
</template>

<script>
import RockHeader from "@/components/RockHeader";
import RockFooter from "@/components/RockFooter";
import HeaderNews from "@/components/HeaderNews";

export default {
  name: "MainLayout",
  props: ["title", "breadcrumbs"],
  components: {
    HeaderNews,
    RockHeader,
    RockFooter,
  },
};
</script>
